import React,{Component} from "react";

// reactstrap components
import {
  Col,
  Progress,
  Label,
  Button,
  Input
} from "reactstrap";
import { Checkbox } from '@material-ui/core';
import Sound from 'react-sound';
import styles from "components/test.css";
import {base,s3base} from "api.js";
import "components/custom.css";
import ReactHtmlParser from 'react-html-parser';
import PlaybackSpeedBtns from "components/PracticeMaterial/PlaybackSpeedBtns";
import SelectVoiceDropDown from "components/PracticeMaterial/SelectVoiceDropDown.jsx";

class Highlight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:10,
      recordCount:0,
      recordId:1,
      intervalId:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      selectedValue:'',
      answerData:[],
      soundPer:0,
      answerFlag:true,
      volume:100,
      correctFlag:true,
      resultObj:{},
      playbackSpeed: 1,
      soundKey: 0,
      question_audio: null,
      defaultSelectedVoice: null,
    };
  }
  componentDidMount() {
    if (!this.props.answer) {
      
      // select audio.
      let question_audio = null;
      let defaultSelectedVoice = null;
      if ('question_audios' in this.props.data && this.props.data.question_audios.length > 0) {
        let x = parseInt(Math.random() * 6);

        const { question_audios } = this.props.data;
        question_audio = `${s3base}/${question_audios[x].value}`;
        defaultSelectedVoice = question_audios[x].label;
      } else {
        const { media_link } = this.props.data;
        question_audio = `${s3base}${media_link}`;
      }

    	var intervalId = setInterval(this.timer, 1000);
      this.setState({
        intervalId,
        question_audio,
        defaultSelectedVoice
      });
    }
  }

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);
        
        let question_audio = null;
        let defaultSelectedVoice = null;
        if ('question_audios' in props.data && props.data.question_audios.length > 0) {
          let x = parseInt(Math.random() * 6);

          const { question_audios } = props.data;
          question_audio = `${s3base}/${question_audios[x].value}`;
          defaultSelectedVoice = question_audios[x].label;
        } else {
          const { media_link } = props.data;
          question_audio = `${s3base}${media_link}`;
        }

        var els = document.querySelectorAll('.highlight_question span');
        for (var i = 0; i < els.length; i++) {
          els[i].classList.remove('wrong-anss');
          els[i].classList.remove('correct-anss');
          els[i].classList.remove('blue-bg');
          els[i].classList.remove('highlight1');
        }
  			return {
          answerData: [],
          correctFlag: true,
          recordCount: 0,
          soundPer: 0,
          isRecording: false,
          secondCount: 10,
          questionId: props.data.id,
          blobURL: '',
          reset: true,
          soundStatus: true,
          answerFlag: true,
          question_audio,
          defaultSelectedVoice
        };
  		}else{
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id};
  			}
  		}

  		}

  }

  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }
  changeRange = (e) => {
      this.setState({volume: e.target.value});
  }
	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);
		}
		this.setState({ secondCount: this.state.secondCount -1 });
	}

  handleSongFinishedPlaying = () => {
    this.setState({'soundStatus':false});
    this.props.soundChecked();
    this.props.showResult();
  }



	timerRecord = () => {
		if (this.state.recordCount === 40) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });
	}

  handleChange = (event) => {
    this.setState({selectedValue: event.target.value});
  };
	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}



  selectSpan = (e) => {
    let arr = this.state.answerData;
    if (e.target.className === "highlight1" ) {
      e.target.classList.remove("highlight1");
      arr = arr.filter(function(val) {
        console.log(e.target.innerHTML)
        console.log(val)
          return val !== e.target.innerHTML ;
        },e);
    }else{
      e.target.classList.add("highlight1");
      arr.push(e.target.innerHTML);
    }
    this.props.submit({'answer':arr,'correct':this.props.data.answer.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 ===0) { return i; } }).map(function(x){ return x.split(' ')[x.split(' ').length-1] }),html:document.querySelector('.highlight_question').innerHTML},true);
    this.setState({answerData:arr,resultObj: {'answer':arr,'correct':this.props.data.answer.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 ===0) { return i; } }).map(function(x){ return x.split(' ')[x.split(' ').length-1] }),html:document.querySelector('.highlight_question').innerHTML} });
  }

  answergenerated = () => {
    if (this.state.answerFlag && document.querySelectorAll('.highlight_question .correct').length > 0) {
      this.setState({answerFlag:false});
      let elem = document.querySelectorAll('.highlight_question span');
      for (var i = 0; i < elem.length; i++) {
          let hig = document.querySelectorAll('.highlight_question span')[i].classList.value;
          let higText = document.querySelectorAll('.highlight_question span')[i].textContent;
          if (hig == "correct hidd") {
            document.querySelectorAll('.highlight_question span')[i-1].classList.add('blue-bg');
          }
      }
      for (var i = 0; i < elem.length; i++) {
          let hig = document.querySelectorAll('.highlight_question span')[i].classList.value;
          let higText = document.querySelectorAll('.highlight_question span')[i].textContent;
          let corr = "";
          if (elem.length > (i+1)) {
            corr = document.querySelectorAll('.highlight_question span')[i+1].classList.value;
          }
        if (hig.match("highlight1")) {
            if (corr == "correct hidd") {
              //document.querySelectorAll('.highlight_question span')[i].innerHTML = higText;
              //document.querySelectorAll('.highlight_question span')[i].classList.add('correct-anss');
              document.querySelectorAll('.highlight_question span')[i].classList.remove('blue-bg');
              document.querySelectorAll('.highlight_question span')[i].classList.add('correct-anss');
            }else{
            //document.querySelectorAll('.highlight_question span')[i].innerHTML = higText;
            document.querySelectorAll('.highlight_question span')[i].classList.remove('blue-bg');
            document.querySelectorAll('.highlight_question span')[i].classList.add('wrong-anss');
          }

        }

      }
    }

  }

  updateCorrect = () =>{
    this.props.submit({'answer':[],'correct':this.props.data.answer.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 ===0) { return i; } }).map(function(x){ return x.split(' ')[x.split(' ').length-1] }),html:''});
    this.setState({correctFlag:false,resultObj: {'answer':[],'correct':this.props.data.answer.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 ===0) { return i; } }).map(function(x){ return x.split(' ')[x.split(' ').length-1]}),html:''} });
  }

  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({secondCount:0});
  };

  changePlaybackSpeed = (speed) => {
    this.setState((prevState) => ({
      soundKey: prevState.soundKey + 1
    }));
    this.setState({playbackSpeed: speed});
  }

  changeAudio = (selected) => {

    const { question_audios } = this.props.data;
    const selected_audio = question_audios.filter((audio, i) => {
      return audio.label === selected.label;
    });

    this.setState({
      question_audio: `${s3base}/${selected_audio[0].value}`,
      playStatus: Sound.status.PLAYING
    });
  }

  render() {
  	let question ="";
    let answer ="";
    let correct = ""
    if (this.props.restart) {
      this.props.restartQue();
    }

    if (this.props.answer) {
      this.answergenerated();
    }
    if (this.props.stopRecording) {
      this.stopTest();
    }
  	if (this.props.data) {
  		question = this.props.data.question;
      answer = this.props.data.answer;
      correct = answer.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter((v,k) => { if (k%2 == 1) { return v.trim(); } });
      if (this.state.correctFlag) {
        this.updateCorrect();
      }
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false});
      document.querySelectorAll("span.highlight").forEach(function (job) {
        job.classList.remove('highlight')
      });
  	}
    return (
      <>
      <Col xs={12}>
      	<h4 className="infoText"></h4>
      </Col>
      {!this.props.resultMode && <Col xs={12}>
      	<div className="col-lg-12 p-0 text-center">
  		  <div className="currentStatus">
          <p>Current Status:{this.state.soundStatus == true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
            {this.state.secondCount == 0 && this.state.soundStatus && ' Playing' }

            <br></br>
            Volume
            <Input placeholder="Volume" type="range" min="0" defaultValue={this.state.volume} step="1" onInput={(e) => this.changeRange(e)} max="100"  />
            <br></br>
            <Progress color="info" value={(this.state.soundPer)} />
          
            {!this.props.testMode && (
              <div className="d-flex justify-content-between">
                <div className='stop-btn'>
                  {this.state.secondCount!==0 && this.state.blobURL === '' && !this.state.isRecording && <Button color="success" onClick={this.skip} size="sm">Skip</Button> }
                </div>
                
                <div className="stop-btn clearfix">
                  <div className="select-voice pull-left">
                    {this.state.defaultSelectedVoice !== null &&
                      <SelectVoiceDropDown
                        voices={this.props.voices}
                        selected={this.state.defaultSelectedVoice}
                        changeAudio={this.changeAudio}
                      />
                    }
                  </div>
                  <div className="select-speed pull-right">
                    <PlaybackSpeedBtns
                      changePlaybackSpeed={this.changePlaybackSpeed}
                      playbackSpeed={this.state.playbackSpeed}
                    />
                  </div>
                </div>
              </div>
            )}
          </p>

            </div>
          <Col xs={12}>
            {this.props.data && !this.props.resultMode && this.state.soundStatus && this.state.secondCount === 0 && <Sound
              url={this.state.question_audio}
              playStatus={Sound.status.PLAYING}
              onFinishedPlaying={this.handleSongFinishedPlaying}
              onPlaying={this.handleSongPlaying}
              volume={this.state.volume}
              playbackRate={this.state.playbackSpeed}
              key={this.state.soundKey}
            />}
          </Col>
    		  <Col xs={12}>
    		  	{ this.state.blobURL && <audio src={this.state.blobURL} controls="controls" />}
    		  </Col>
    		</div>
      </Col> }

      <Col xs={12}>
      {!this.props.testMode && <h3 className="question highlight_question">{this.props.data && !this.props.resultMode && answer.replace(/<span[^>]*>?/g, ' __').replace(/<[^>]*span>?/g, '__').split(' ').map(function(item){ if(item.match(/__.*__/g)){ return  <React.Fragment>{this.props.answer && <span className="correct hidd">{item.replace(/__/g,'')}</span>}</React.Fragment> }else{ return <React.Fragment><span className="" onClick={this.selectSpan} >{item}</span>&nbsp; </React.Fragment>; }},this)}</h3>}
      {this.props.testMode && <h3 className="question highlight_question">{this.props.data && !this.props.resultMode && answer.replace(/<span[^>]*>?/g, ' __').replace(/<[^>]*span>?/g, '__').split(' ').map(function(item){ if(item.match(/__.*__/g)){ return  <React.Fragment>{<span className="correct hidd">{item.replace(/__/g,'')}</span>}</React.Fragment> }else{ return <React.Fragment><span className="" onClick={this.selectSpan} >{item}</span>&nbsp; </React.Fragment>; }},this)}</h3>}
      </Col>
      <div className="col-xs-12 answers">
      {this.props.data && this.props.data.option.map(function(item){
              return <React.Fragment>
                      <Label className={ this.props.answer && item.correct === 1 && 'correct-line' }>
                        <Checkbox
                            value={item.id}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            name="answer"
                          />{' '}
                        {item.options}
                      </Label>

                    </React.Fragment>;
            },this) }
        <Col xs={12}><h5>{this.props.data && this.props.resultMode && ReactHtmlParser(answer.replace(/<span[^>]*>?/g, '<b className="correct" style="display:none">').replace(/<[^>]*span>?/g, '</b>&nbsp;').replace(/__add_blank__/g, '')) }</h5></Col>
      </div>
      </>
    );
  }
}
export default Highlight;
